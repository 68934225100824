<template>
  <NoAuthLayout
    :submit="submit"
    :Welcome_Msg="Welcome_Msg">
    <button class="button-warning-cus" type="submit">
      {{Connexion_Label}}<span class="arrow"/>
    </button>
  </NoAuthLayout>
</template>

<script>
import NoAuthLayout from "../layouts/noauth.layout";
import { FIRST_CONNECTION_PATH } from '../router/pathes';

export default {
  name: "Login",
  components: {
    NoAuthLayout
  },
  data() {
    return {
      Page_Title : "Login page",
      Welcome_Msg : "Simplifiez vos démarches, gagnez du temps, accélérez votre projet : connectez-vous à votre espace dédié.",
      Connexion_Label : "Connexion",
    };
  },
  methods: {
    firstLogin() {
      this.$router.push({ path: FIRST_CONNECTION_PATH })
    },
    submit() {
      this.auth.loginWithRedirect();
    }
  },
};
</script>

<style scoped lang="scss">

</style>
